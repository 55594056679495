import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useGetShopQuery } from "../../api/shopSlice";
import { Spinner, IconButton } from "@material-tailwind/react";
import { MdRemoveRedEye, MdPreview } from "react-icons/md";
import {
  useGetDebtListByShopMutation,
  useGetPaidListByShopMutation,
  useGetPaidListDetailsByShopMutation,
} from "../../api/DebtListApiSlice";
import MyToolTip from "../../components/MyToolTip";
import CustomerDebitVoucherList from "../../components/CustomerDebitVoucherList";
import PaidListDetailsDialog from "../../components/PaidListDetailsDialog";
const DebtList = () => {
  const [shopId, setShopId] = useState(null);
  const [debtDataList, setDebtDataList] = useState([]);
  const [filteredDebtList, setFilteredDebtList] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [paidList, setPaidList] = useState([]);
  const [open, setOpen] = useState(false);
  const [showDetails, setShowDetails] = useState([]);
  const [openList, setOpenList] = useState(false);
  const [showDetail, setShowDetail] = useState([]);

  const { data: shopData, isLoading: isLoadingShop } = useGetShopQuery();

  const [
    fetchDebtList,
    { data: debtData, isLoading: debtLoading, isSuccess: debtSuccess },
  ] = useGetDebtListByShopMutation();

  const [
    trigger,
    { data: paid, isLoading: paidLoading, isSuccess: paidSuccess },
  ] = useGetPaidListByShopMutation();

  const [
    fetchDetails,
    { data: details, isLoading: detailsLoading, isSuccess: detailsSuccess },
  ] = useGetPaidListDetailsByShopMutation();

  const shopOptions = useMemo(
    () =>
      shopData?.data.map((shop) => ({
        value: shop.id,
        label: shop.name,
      })) || [],
    [shopData]
  );

  useEffect(() => {
    if (debtSuccess) {
      const mappedData =
        debtData?.data.map((debt, index) => ({
          ...debt,
          id: index + 1,
          //   uid: debt.id,
          no: index + 1,
          name: debt.customer.name,
          phone: debt.customer.phone,
          bill: debt.customer.note,
        })) || [];
      setDebtDataList(mappedData);
    }
  }, [debtData]);

  useEffect(() => {
    if (paidSuccess) {
      setPaidList([
        ...paid?.data.map((debt, i) => ({
          ...debt,
          No: i + 1,
          id: i + 1,
          name: debt.customer?.name,
          phone: debt.customer?.phone,
        })),
      ]);
    }
  }, [paid, paidSuccess]);

  // Filter data based on selected tab
  useEffect(() => {
    if (tabValue === 0) {
      setFilteredDebtList([...debtDataList]); // All debts
    } else if (tabValue === 1) {
      setFilteredDebtList(
        paidList // Paid debts
      );
    } else if (tabValue === 2) {
      setFilteredDebtList(
        debtDataList.filter((debt) => debt.customer.note > 0) // Remaining debts
      );
    }
  }, [tabValue, debtDataList]);

  useEffect(() => {
    if (detailsSuccess) {
      setShowDetail(details?.data);
      setOpen(true);
    }
  }, [details]);

  const handleTabChange = (event, newValue) => setTabValue(newValue);

  const handleShopChange = (selectedShop) => {
    setDebtDataList([]);
    setFilteredDebtList([]);
    setShopId(selectedShop.value);
    fetchDebtList(selectedShop.value);
    trigger(selectedShop.value);
  };

  const columns = useMemo(
    () => [
      { field: "no", headerName: "No.", width: 50 },
      { field: "name", headerName: "Name", flex: 1 },
      { field: "phone", headerName: "Phone", flex: 1 },
      { field: "totalBill", headerName: "Total Bill Amount", flex: 1 },
      {
        field: "vouchers",
        headerName: "Voucher List",
        sortable: true,
        flex: 1,
      },
      {
        field: "created_at",
        headerName: "Action",
        sortable: true,
        flex: 1,
        renderCell: (params) => (
          <IconButton
            onClick={() => showDebtDetails(params.row)}
            className="w-6 h-6 bg-blue-200 p-4"
          >
            <MdRemoveRedEye className="h-4 w-4 text-blue-600 font-extrabold" />
          </IconButton>
        ),
      },
    ],
    []
  );

  const paidDebtsColumns = [
    { field: "id", headerName: "No.", width: 50 },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
    },
    {
      field: "paid",
      headerName: "Paid Bill Amount",
      flex: 1,
    },
    {
      field: "view",
      headerName: "View",
      sortable: true,
      flex: 1,
      renderCell: (params) => (
        <div>
          <MyToolTip style={`bg-amber-500`} content={"Voucher List"}>
            <IconButton
              onClick={() => {
                let body = { shopId: shopId, id: params.row.customer.id };
                console.log(body);
                fetchDetails(body);
              }}
              className="w-6 h-6 bg-blue-200 p-4"
            >
              <MdRemoveRedEye className="h-4 w-4 text-blue-600 font-extrabold" />
            </IconButton>
          </MyToolTip>
        </div>
      ),
    },
  ];

  const remainDebtsColumns = useMemo(
    () => [
      { field: "id", headerName: "No.", width: 50 },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
      },
      {
        field: "phone",
        headerName: "Phone",
        flex: 1,
      },
      {
        field: "bill",
        headerName: "Bill Amount",
        flex: 1,
      },
      {
        field: "vouchers",
        headerName: "Voucher List",
        flex: 1,
      },
      {
        field: "action",
        headerName: "Paid Debt",
        flex: 1,
        renderCell: (params) => (
          <div className="flex flex-row gap-4 justify-center items-center w-full">
            {/* <div> */}
            <div>
              <MyToolTip style={`bg-amber-500`} content={"Voucher List"}>
                <IconButton
                  onClick={() => showDebtDetails(params.row)}
                  className="w-6 h-6 bg-amber-200 p-4"
                >
                  <MdPreview className="h-4 w-4 text-amber-600 font-extrabold" />
                </IconButton>
              </MyToolTip>
            </div>
          </div>
        ),
      },
    ],
    []
  );

  const showDebtDetails = (row) => {
    setShowDetails(row.voucherList);
    setOpenList(true);
  };

  return (
    <div className="flex flex-col gap-4 px-8 py-6">
      {/* Caption */}
      <div className="flex justify-start">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Debts List By Shop
        </p>
      </div>

      {isLoadingShop ? (
        <div className="w-full flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div>
          {/* Shop Selector */}
          <div className="w-full px-6 py-2 h-20 border-2 border-gray-200 flex gap-2 items-end rounded-xl">
            <div className="flex-1 m-2">
              <Select
                classNamePrefix="select"
                name="Shop"
                options={shopOptions}
                onChange={handleShopChange}
              />
            </div>
          </div>

          {/* Tab Views */}
          <div className="w-full flex justify-center items-center">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="debt tabs"
            >
              <Tab label="All Debts List" />
              <Tab label="Paid List" />
              <Tab label="Remain List" />
            </Tabs>
          </div>
        </div>
      )}

      {/* Data Grid per Tab */}
      {tabValue === 0 && ( // all debt list
        <DataGrid
          sx={{ minHeight: "300px" }}
          rows={debtDataList || []}
          columns={columns}
          loading={debtLoading}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          disableRowSelectionOnClick
          slots={{ toolbar: GridToolbar }}
        />
      )}
      {tabValue === 1 && ( // paid list
        <DataGrid
          sx={{ minHeight: "300px" }}
          rows={filteredDebtList || []}
          columns={paidDebtsColumns}
          loading={paidLoading}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          disableRowSelectionOnClick
          slots={{ toolbar: GridToolbar }}
        />
      )}
      {tabValue === 2 && ( // remain list
        <DataGrid
          sx={{ minHeight: "300px" }}
          rows={debtDataList.filter((debt) => debt.customer.note > 0) || []}
          columns={remainDebtsColumns}
          loading={debtLoading}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          disableRowSelectionOnClick
          slots={{ toolbar: GridToolbar }}
        />
      )}

      <CustomerDebitVoucherList
        open={openList}
        handleClose={() => setOpenList(false)}
        showDetails={showDetails}
      />

      <PaidListDetailsDialog
        open={open}
        handleClose={() => setOpen(false)}
        body={showDetail || []}
      />
    </div>
  );
};

export default DebtList;
