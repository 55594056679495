import Layout from "./layouts/layout";
import Category from "./pages/category/Category";
import Dashboard from "./pages/dashboard/dashboard";
import AddProducts from "./pages/products/AddProducts";
import Product from "./pages/products/Product";
import Shop from "./pages/shops/Shop";
import { Routes, Route } from "react-router-dom";
import Supplier from "./pages/supplier/Supplier";
import Unit from "./pages/unit/Unit";
import UnitJoin from "./pages/unit/UnitJoin";
import User from "./pages/user/User";
import AddUser from "./pages/user/AddUser";
import Purchase from "./pages/purchase/Purchase";
import PurchaseDetails from "./pages/purchase/PurchaseDetails";
import Distribution from "./pages/distribution/Distribution";
import DistributeDetails from "./pages/distribution/DistributeDetails";
import PurchaseRecords from "./pages/purchase/PurchaseRecords";
import PurchaseHistory from "./pages/purchase/PurchaseHistory";
import DistributeRecords from "./pages/distribution/DistributeRecords";
import DistributionHistory from "./pages/distribution/DistributionHistory";
import Splash from "./pages/splash/Splash";
import Stock from "./pages/stock/Stock";
import ShopStock from "./pages/stock/ShopStock";
import CurrencyExchange from "./pages/general/CurrencyExchange";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import init from "./store/network";
import SupplierStatement from "./pages/supplier/SupplierStatement";
import ProductTransferList from "./pages/stock/ProductTransferList";
import ProductTransfer from "./pages/stock/ProductTransfer";
import LoginPage from "./pages/auth/LoginPage";
import HomePage from "./pages/splash/Home";
import MonthlyReport from "./pages/report/MonthlyReport";
import DailyReport from "./pages/report/DailyReport";
import DailySaleProduct from "./pages/report/DailySaleProduct";
import ClientMonthlyReport from "./pages/report/ClientMonthlyReport";
import ClientDailyReport from "./pages/report/ClientDailyReport";
import ClientDailySaleProduct from "./pages/report/ClientDailySaleProduct";
import DevicePermit from "./pages/device/DevicePermit";
import ShopCoreValue from "./pages/stock/ShopCoreValue";
import ProductLocation from "./pages/products/ProductLocation";
import PendingVoucherList from "./pages/stock/PendingVoucherList";
import DebtList from "./pages/debtList/DebtList";

function App() {
  // const {lstProduct, paginate} = useSelector((state) => state.productList);

  // const dispatch = useDispatch();

  // useEffect(()=>{
  //   if(lstProduct.length == 0){

  //     init(dispatch)
  //   }
  // },[lstProduct])

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/splash" element={<Splash />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/product" element={<Product />} />
        <Route path="/addProduct" element={<AddProducts />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/supplier" element={<Supplier />} />
        <Route path="/unit" element={<Unit />} />
        <Route path="/unit_relationship" element={<UnitJoin />} />
        <Route path="/distribution" element={<Distribution />} />
        <Route path="/category" element={<Category />} />
        <Route path="/daily-report" element={<DailyReport />} />
        <Route path="/daily-sale-product" element={<DailySaleProduct />} />
        <Route path="/monthly-report" element={<MonthlyReport />} />
        <Route path="/client-daily-report" element={<ClientDailyReport />} />
        <Route
          path="/client-daily-sale-product"
          element={<ClientDailySaleProduct />}
        />
        <Route
          path="/client-monthly-report"
          element={<ClientMonthlyReport />}
        />
        <Route path="/purchase" element={<Purchase />} />
        <Route path="/purchase_records" element={<PurchaseRecords />} />
        <Route path="purchase-history/:id" element={<PurchaseHistory />} />
        <Route path="/purchase-details/:voc" element={<PurchaseDetails />} />
        <Route
          path="/distribute-details/:voc"
          element={<DistributeDetails />}
        />
        <Route path="/distribute-records" element={<DistributeRecords />} />
        <Route
          path="/distribute-history/:voc"
          element={<DistributionHistory />}
        />
        <Route path="/stock" element={<Stock />} />
        <Route path="/shop-stock" element={<ShopStock />} />
        <Route path="/currency-exchange" element={<CurrencyExchange />} />
        <Route path="/user" element={<User />} />
        <Route path="/addUser" element={<AddUser />} />
        <Route path="/supplier-statement" element={<SupplierStatement />} />
        <Route
          path="/product-transfer-list"
          element={<ProductTransferList />}
        />
        <Route path="/product-transfer" element={<ProductTransfer />} />
        <Route path="/setting" element={<DevicePermit />} />
        <Route path="/shop-core-value" element={<ShopCoreValue />} />
        <Route path="/product-location" element={<ProductLocation />} />
        <Route path="/pending-vouchers" element={<PendingVoucherList />} />
        <Route path="/shop-debts-list" element={<DebtList />} />
      </Routes>
    </Layout>
  );
}

export default App;
