import { ApiSlice } from "./ApiSlice";

export const shopByDebtListApiSlice = ApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDebtListByShop: builder.mutation({
      query: (shopId) => ({
        url: `/customer-debt-list/${shopId}`,
        method: "GET",
      }),
    }),

    getPaidListByShop: builder.mutation({
      query: (shopId) => ({
        url: `/customer-paid-list/${shopId}`,
        method: "GET",
      }),
    }),

    getPaidListDetailsByShop: builder.mutation({
      query: ({ shopId, id }) => ({
        url: `/paid-details/${shopId}/${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetDebtListByShopMutation,
  useGetPaidListByShopMutation,
  useGetPaidListDetailsByShopMutation,
} = shopByDebtListApiSlice;
