import { ApiSlice } from "./ApiSlice";

export const distributeVoucherSlice = ApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDistributeVoucher: builder.query({
      query: () => "/store-voucher",
      providesTags: ["distributeVoc"],
    }),

    getDistributeVoucherById: builder.query({
      query: (id) => `/store-voucher/${id}`,
      providesTags: ["distributeVoc"],
    }),

    addDistributeVoucher: builder.mutation({
      query: (body) => ({
        url: "/store-voucher",
        method: "POST",
        body,
      }),
      invalidatesTags: ["distributeVoc"],
    }),

    editDistributeVoucher: builder.mutation({
      query: (body) => ({
        url: `/store-voucher/${body.get("id")}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["distributeVoc"],
    }),

    deleteDistributeVoucher: builder.mutation({
      query: (id) => ({
        url: `/store-voucher/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["distributeVoc"],
    }),

    getPendingVoucherByShop: builder.mutation({
      query: (id) => `/sale-voucher/${id}/pending`,
      // providesTags: ['distributeVoc'],
    }),
    deletePendingVoucher: builder.mutation({
      query: ({ shopId, id }) => ({
        url: `/sale-voucher/${shopId}/pending/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["distributeVoc"],
    }),
  }),
});

export const {
  useGetDistributeVoucherQuery,
  useGetDistributeVoucherByIdQuery,
  useAddDistributeVoucherMutation,
  useEditDistributeVoucherMutation,
  useDeleteDistributeVoucherMutation,
  useGetPendingVoucherByShopMutation,
  useDeletePendingVoucherMutation,
} = distributeVoucherSlice;
