import React, { useState, useRef, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import {
  useEditProductMutation,
  useGetProductByIdMutation,
  useGetProductQuery,
  useDeleteProductMutation,
  useEditProductChainQtyMutation,
  useEditProductFromStockMutation,
} from "../../api/productSlice";
import { useGetStockByShopMutation } from "../../api/shopSlice";
import { useGetShopQuery } from "../../api/shopSlice";
import Select from "react-select";
import { BiEdit } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import {
  Drawer,
  Button,
  Avatar,
  Tooltip,
  Input,
  Spinner,
  IconButton,
  Typography,
} from "@material-tailwind/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";

const ShopStock = () => {
  //Api Calling
  const {
    data: dataShop,
    isLoading: isLoadingShop,
    isSuccess: isSuccessShop,
    refetch: refetchShop,
  } = useGetShopQuery();
  const [
    trigger,
    {
      data: dataStock,
      isLoading: isLoadingStock,
      isSuccess: isSuccessStock,
      refetch: refetchStock,
    },
  ] = useGetStockByShopMutation();

  const rtkLstProduct = useSelector((state) => state.productList.lstProduct);

  const [editProductItem, editResult] = useEditProductFromStockMutation();
  const [editProductChainQty, resultQty] = useEditProductChainQtyMutation();

  const [editProduct, setEditProduct] = useState();
  const [loading, setLoading] = useState(true);
  const [lstShop, setLstShop] = useState([]);
  const [lstProduct, setLstProduct] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const [open, setOpen] = useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  const refEditName = useRef();
  const refEditBarcode = useRef();
  const refEditQty = useRef();
  const [pId, setPId] = useState();
  const [shopId, setShopId] = useState();

  useEffect(() => {
    setLstShop([]);
    dataShop?.data.map((shop, i) => {
      setLstShop((current) => [...current, { value: shop, label: shop.name }]);
    });
  }, [dataShop?.data]);

  //Create list of product data to show on table
  useEffect(() => {
    if (isSuccessStock) {
      setLstProduct([]);
      console.log(dataStock?.data);
      const rows = dataStock?.data.map((p, index) =>
        true
          ? {
              ...p,
              id: p.product_id,
              no: index + 1,
              img: p.product.img,
              name: p.product.name,
              barcode: p.product.barcode,
              sell_price1:
                p.product.purchase != null
                  ? Math.ceil(
                      ((p.product.purchase.price +
                        p.product.purchase.price * (p.product.remark / 100)) *
                        p.product.currency.ChangePrice) /
                        100
                    ) * 100
                  : 0,
              categoryName: p.product.category.name,
              unitName: p.product.unit.name,
              prodId: p.product.id,
            }
          : { ...p }
      );
      setLstProduct([...rows]);
      setLoading(false);
    }
  }, [dataStock?.data]);

  let formData = new FormData();
  const editProductHandler = async (e) => {
    console.log(e);
    formData.append("id", editProduct);
    formData.append("barcode", refEditBarcode.current.value);
    formData.append("name", refEditName.current.value);

    await editProductItem(formData);

    closeDrawer();
    trigger(shopId);
  };

  const editProductChainHandler = async () => {
    let body = {
      shop_id: shopId,
      product_id: pId,
      qty: refEditQty.current.value,
    };
    await editProductChainQty(body);

    trigger(shopId);
    handleCloseDialog();
  };

  //Create list of product data to show on table
  // useEffect(() => {
  //     const rows = rtkLstProduct.map((p, index) =>
  //     true
  //         ? {
  //             ...p,
  //             no: index + 1,
  //             categoryName: p.category.name,
  //             unitName: p.unit.name,
  //             prodId: p.id,
  //         }
  //         : { ...p }
  //     );
  //     setLstProduct([...rows]);
  //     setLoading(false);

  // }, [rtkLstProduct]);

  //Table columns
  const columns = [
    {
      field: "no",
      headerName: "No.",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "img",
      headerName: "Image",
      width: 100,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Avatar
          src={`https://warehouse.futuretechmm.net/pos-vendor-backend/public/${params.value}`}
          className="w-16 h-16 border-blue-gray-50 bg-blue-gray-50/50 object-contain"
        />
      ),
    },
    {
      field: "name",
      headerName: "Product Name",
      flex: 1,
      editable: false,
    },
    {
      field: "categoryName",
      headerName: "Category",
      width: 150,
      editable: false,
    },
    {
      field: "unitName",
      headerName: "Unit",
      width: 80,
      editable: false,
    },
    {
      field: "qty",
      headerName: "Stock",
      type: "number",
      width: 110,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "barcode",
      headerName: "Barcode",
      width: 120,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "sell_price1",
      headerName: "Sell Price",
      type: "number",
      width: 100,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "prodId",
      headerName: "Action",
      width: 150,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div className="flex flex-row gap-2">
          <Tooltip content="Change Qty" className="flex-1 bg-green-500">
            <IconButton
              className="w-6 h-6 bg-green-200 p-4"
              onClick={() => {
                setPId(params.row.id);
                handleOpenDialog();
              }}
            >
              <BiEdit className="h-4 w-4 text-green-600" />
            </IconButton>
          </Tooltip>
          <Tooltip content="Edit" className="flex-1 bg-blue-500">
            <IconButton
              // onMouseOver={()=>setCateId(params.row.category_id)}
              onClick={() => {
                setEditProduct(params.row.id);
                refEditName.current.value = params.row.name;
                refEditBarcode.current.value = params.row.barcode;

                openDrawer();
              }}
              className="w-6 h-6 bg-blue-200 p-4"
            >
              <FaEdit className="h-4 w-4 text-blue-600 font-extrabold" />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-4">
      <>
        {/* Drawer to Edit product */}
        <Drawer size={300} placement="right" open={open} onClose={closeDrawer}>
          <div className="mb-2 flex items-center justify-between p-4">
            <Typography variant="lead" color="blue-gray">
              Edit Product
            </Typography>
            <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
              <XMarkIcon strokeWidth={2} className="h-5 w-5" />
            </IconButton>
          </div>
          <div className="h-full overflow-y-auto pb-20">
            <form className="flex flex-col gap-6 p-4 items-center">
              <Input
                type="text"
                label="Name"
                defaultValue={open ? refEditName.current.value : ""}
                inputRef={refEditName}
              />
              <Input
                type="text"
                label="Barcode"
                defaultValue={open ? refEditBarcode.current.value : ""}
                inputRef={refEditBarcode}
              />
              {/* <Input
                type="text"
                label="Profit Percent"
                inputRef={refProfitPercent}
              /> */}
              <Button onClick={editProductHandler}>
                {editResult.isLoading ? <Spinner /> : "Save"}
              </Button>
            </form>
          </div>
        </Drawer>
        {/* End of Drawer to Edit product */}
      </>
      {/*Edit Qty */}
      <Dialog
        open={openDialog}
        onClose={(e, r) => {
          if (r == "backdropClick" && resultQty.isSuccess) {
            handleCloseDialog();
          }
        }}
      >
        <DialogTitle>Change Quantity</DialogTitle>
        <DialogContent>
          <TextField
            defaultValue={1}
            inputRef={refEditQty}
            autoFocus
            margin="dense"
            id="name"
            label="Qty"
            type="number"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={!resultQty.isLoading ? handleCloseDialog : () => {}}>
            Cancel
          </Button>
          <Button onClick={() => editProductChainHandler()}>
            {resultQty.isLoading ? <Spinner /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* End of Edit Qty */}

      {/* Caption */}
      <div className="flex flex-row w-full justify-start">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Stock in Shop
        </p>
      </div>

      {/* Select Shop Section */}
      <div className="w-full px-6 py-2 h-20 border-2 border-gray-200 xl:flex xl:flex-row gap-2 items-end grid grid-cols-1 justify-center rounded-xl">
        {/* <form className="flex flex-row items-end" action={supplierHandler}> */}
        <div className="flex-1 m-2">
          <Select
            classNamePrefix="select"
            name="Shop"
            options={lstShop}
            onChange={(e) => {
              setLstProduct([]);
              setLoading(true);
              console.log(e.value);
              setShopId(e.value.id);
              trigger(e.value.id);
            }}
          />
        </div>

        {/* </form> */}
      </div>

      {/* Product List Table */}
      <div className="w-full h-full">
        {!loading ? (
          <DataGrid
            rows={lstProduct}
            columns={columns}
            rowHeight={80}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            //   filterModel={{
            //     items: [{ field: 'stock', operator: '>', value: 1000 }],
            //   }}
            density="compact"
            pageSizeOptions={[5, 10, 25, 50, 100]}
            disableRowSelectionOnClick
            slots={{ toolbar: GridToolbar, loadingOverlay: LinearProgress }}
            loading={loading}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        ) : !isLoadingStock ? (
          <div className="flex flex-row p-4 justify-center bg-green-100 text-green-500 rounded-lg">
            No Items
          </div>
        ) : (
          <div className="flex flex-row p-4 justify-center">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default ShopStock;
