import React, { useState, useEffect } from "react";
import Select from "react-select";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useGetShopQuery } from "../../api/shopSlice";
import { FaTrash } from "react-icons/fa";
import { Tooltip, Spinner, IconButton } from "@material-tailwind/react";
import { LinearProgress } from "@mui/material";

import {
  useGetPendingVoucherByShopMutation,
  useDeletePendingVoucherMutation,
} from "../../api/distributeVoucherSlice";

const PendingVoucherList = () => {
  const [loading, setLoading] = useState(true);
  const [lstShop, setLstShop] = useState([]);
  const [lstPendingVoucherList, setLstPendingVoucherList] = useState([]);
  const [shopId, setShopId] = useState();
  const isLoadingStock = false;

  const {
    data: dataShop,
    isLoading: isLoadingShop,
    isSuccess: isSuccessShop,
    refetch: refetchShop,
  } = useGetShopQuery();

  const [
    trigger,
    {
      data: pendingVoucher,
      isLoading: isLoadingVoucher,
      isSuccess: isSuccessVoucher,
      refetch: refetchVoucher,
    },
  ] = useGetPendingVoucherByShopMutation();

  const [delPending, result] = useDeletePendingVoucherMutation();

  console.log(isLoadingVoucher);
  const columns = [
    {
      field: "no",
      headerName: "No.",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "id",
      headerName: "Voucher ID",
      flex: 1,
      editable: false,
    },
    {
      field: "created_at",
      headerName: "Create Date",
      width: 200,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "prodId",
      headerName: "Action",
      width: 150,
      editable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div className="flex flex-row gap-2">
          <Tooltip content="Delete Unused" className="flex-1 bg-green-500">
            <IconButton
              className="w-6 h-6 bg-red-200 p-4"
              onClick={() => {
                // setPId(params.row.id);
                delPending({
                  shopId: shopId,
                  id: params.row.id,
                });
                // handleOpenDialog();
                trigger(shopId);
              }}
            >
              <FaTrash className="h-4 w-4 text-red-600" />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  // if (isSuccessVoucher) {
  //   console.log(pendingVoucher);
  //   setLstPendingVoucherList(pendingVoucher.data);
  // } else {
  //   setLstPendingVoucherList([]);
  // }

  useEffect(() => {
    setLstShop([]);
    dataShop?.data.map((shop, i) => {
      setLstShop((current) => [...current, { value: shop, label: shop.name }]);
    });
  }, [dataShop?.data]);

  useEffect(() => {
    if (isSuccessVoucher) {
      setLoading(false);

      const dataRow = pendingVoucher?.data?.map((vocher, index) => {
        return {
          ...vocher,
          no: index + 1,
        };
      });

      setLstPendingVoucherList(dataRow);
    }
  }, [pendingVoucher?.data]);

  return (
    <div className="flex flex-col gap-4 px-8 py-6">
      {/* Caption */}
      <div className="flex flex-row w-full justify-start">
        <p className="px-4 py-2 bg-[#57626c] rounded-lg text-white font-bold">
          Pending Voucher List
        </p>
      </div>

      {isLoadingShop ? (
        <div className="w-full h-full justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div>
          {/* Select Shop Section */}
          <div className="w-full px-6 py-2 h-20 border-2 border-gray-200 xl:flex xl:flex-row gap-2 items-end grid grid-cols-1 justify-center rounded-xl">
            {/* <form className="flex flex-row items-end" action={supplierHandler}> */}
            <div className="flex-1 m-2">
              <Select
                classNamePrefix="select"
                name="Shop"
                options={lstShop}
                onChange={(e) => {
                  setLstPendingVoucherList([]);
                  setLoading(true);
                  console.log(e.value);
                  setShopId(e.value.id);
                  trigger(e.value.id);
                }}
              />
            </div>

            {/* </form> */}
          </div>

          {/* Pending Voucher List Table */}
          <div className="w-full h-full">
            {!loading ? (
              <DataGrid
                rows={lstPendingVoucherList}
                columns={columns}
                rowHeight={80}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                //   filterModel={{
                //     items: [{ field: 'stock', operator: '>', value: 1000 }],
                //   }}
                density="compact"
                pageSizeOptions={[5, 10, 25, 50, 100]}
                disableRowSelectionOnClick
                slots={{ toolbar: GridToolbar, loadingOverlay: LinearProgress }}
                loading={loading}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            ) : !isLoadingVoucher ? (
              <div className="flex flex-row p-4 justify-center bg-green-100 text-green-500 rounded-lg">
                No Pending Voucher List
              </div>
            ) : (
              <div className="flex flex-row p-4 justify-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PendingVoucherList;
