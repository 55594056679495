import React, { useState } from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
  accordion,
} from "@material-tailwind/react";
import {
  PresentationChartBarIcon,
  Cog6ToothIcon,
  PowerIcon,
} from "@heroicons/react/24/solid";
import { IoCardOutline } from "react-icons/io5";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  FaUserTie,
  FaSitemap,
  FaUserSecret,
  FaBoxes,
  FaCubes,
  FaReadme,
} from "react-icons/fa";
import { BiCategoryAlt } from "react-icons/bi";
import { BsShop, BsCurrencyExchange } from "react-icons/bs";
import { HiDocumentReport } from "react-icons/hi";
import { RiUnsplashFill } from "react-icons/ri";
import { Link, location } from "react-router-dom";
import Logo from "../assets/TTMM1.png";

const MaterialSideBar = () => {
  const [open, setOpen] = useState(0);
  const [show, setShow] = useState(false);
  const [maxSidebar, setMaxSidebar] = useState(true);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const menuList = [
    {
      title: "Dashboard",
      icon: PresentationChartBarIcon,
      link: "/dashboard",
      accordion: [],
    },
    {
      title: "Supplier",
      icon: FaUserTie,
      link: "/supplier",
      accordion: [],
    },
    {
      title: "Shop",
      icon: BsShop,
      link: "/shop",
      accordion: [],
    },
    {
      title: "Category",
      icon: BiCategoryAlt,
      link: "/category",
      accordion: [],
    },
    {
      title: "Unit",
      icon: FaSitemap,
      link: "/unit",
      accordion: [
        {
          name: "Unit",
          link: "/unit",
        },
        {
          name: "Unit Join",
          link: "/unit_relationship",
        },
      ],
    },
    {
      title: "Product",
      icon: FaBoxes,
      link: "/product",
      accordion: [
        {
          name: "Add New Product",
          link: "/addProduct",
        },
        {
          name: "Product List",
          link: "/product",
        },
        {
          name: "Product Location",
          link: "/product-location",
        },
      ],
    },
    {
      title: "Purchase",
      icon: FaReadme,
      link: "/purchase",
      accordion: [
        {
          name: "New Purchase",
          link: "/purchase",
        },
        {
          name: "Purchase Record",
          link: "/purchase_records",
        },
      ],
    },
    {
      title: "Product Distribution",
      icon: FaCubes,
      link: "/distribution",
      accordion: [
        {
          name: "Distribution",
          link: "/distribution",
        },
        {
          name: "Distribute Record",
          link: "/distribute-records",
        },
      ],
    },
    {
      title: "Stock",
      icon: RiUnsplashFill,
      link: "drock",
      accordion: [
        {
          name: "Stock",
          link: "/stock",
        },
        {
          name: "Shop and Stock",
          link: "/shop-stock",
        },
        {
          name: "Product Transfer",
          link: "/product-transfer",
        },
        {
          name: "Product Transfer List",
          link: "/product-transfer-list",
        },
        {
          name: "Shop Value Amount",
          link: "/shop-core-value",
        },
        {
          name: "Pending Voucher List",
          link: "/pending-vouchers",
        },
      ],
    },
    {
      title: "Currency Exchange",
      icon: BsCurrencyExchange,
      link: "/currency-exchange",
      accordion: [],
    },
    {
      title: "Warehouse Reports",
      icon: HiDocumentReport,
      accordion: [
        {
          name: "Daily Report",
          link: "/daily-report",
        },
        {
          name: "Daily Sale Products",
          link: "/daily-sale-product",
        },
        {
          name: "Monthly Report",
          link: "/monthly-report",
        },
      ],
    },
    {
      title: "Client Shop Reports",
      icon: HiDocumentReport,
      accordion: [
        {
          name: "Daily Report",
          link: "/client-daily-report",
        },
        {
          name: "Daily Sale Products",
          link: "/client-daily-sale-product",
        },
        {
          name: "Monthly Report",
          link: "/client-monthly-report",
        },
      ],
    },
    {
      title: "Debts List",
      icon: IoCardOutline,
      link: "/shop-debts-list",
      accordion: [],
    },
    {
      title: "User",
      icon: FaUserSecret,
      link: "/user",
      accordion: [
        {
          name: "Add New User",
          link: "/addUser",
        },
        {
          name: "User List",
          link: "/user",
        },
      ],
    },
    {
      title: "Setting",
      icon: Cog6ToothIcon,
      link: "/setting",
      accordion: [],
    },
    {
      title: "Logout",
      icon: PowerIcon,
      link: "/logout",
      accordion: [],
    },
  ];

  return (
    <div className="w-72 font-poppins z-30 scrollbar-hide bg-[#1b2c3e]">
      <Card className="w-full h-full max-w-[20rem] shadow-xl shadow-white-900/5 bg-[#1b2c3e]">
        <List className="text-white" key={"asdf"}>
          <ListItem className="p-3 group" key={"logo-group"}>
            <ListItemPrefix>
              <img src={Logo} alt="" className="w-10 h-10 object-cover" />
            </ListItemPrefix>
            <Typography
              color="white"
              className="mr-auto font-normal text-xl group-hover:text-[#1b2c3e]"
            >
              TTMM
            </Typography>
          </ListItem>
          <hr className="my-2 border-white-50" />

          {menuList.map((menu, i) => (
            <>
              {menu.accordion.length == 0 ? (
                <Link to={menu.link} key={menu.link}>
                  <ListItem
                    className="border-b-0 p-3 group"
                    selected={open === i + 1}
                  >
                    <ListItemPrefix>
                      <menu.icon
                        className="h-5 w-5 group-hover:text-[#1b2c3e]"
                        color="white"
                      />
                    </ListItemPrefix>
                    <Typography
                      color="white"
                      className="mr-auto font-normal text-sm group-hover:text-[#1b2c3e]"
                    >
                      {menu.title}
                    </Typography>
                  </ListItem>
                </Link>
              ) : (
                <Accordion
                  open={open === i + 1}
                  icon={
                    <ChevronDownIcon
                      strokeWidth={2.5}
                      className={`mx-auto h-4 w-4 transition-transform ${
                        open === i + 1 ? "rotate-180" : ""
                      }`}
                    />
                  }
                >
                  <ListItem
                    className="p-0 group"
                    selected={open === i + 1}
                    key={"title-group"}
                  >
                    <AccordionHeader
                      onClick={() => handleOpen(i + 1)}
                      className="border-b-0 p-3 group"
                      key={"accor"}
                    >
                      <ListItemPrefix>
                        <menu.icon
                          className="h-5 w-5 group-hover:text-[#1b2c3e]"
                          color="white"
                        />
                      </ListItemPrefix>
                      <Typography
                        color="white"
                        className="mr-auto font-normal text-sm group-hover:text-[#1b2c3e]"
                      >
                        {menu.title}
                      </Typography>
                    </AccordionHeader>
                  </ListItem>
                  <AccordionBody
                    className={
                      open == i + 1 ? "block py-1 text-white" : "hidden"
                    }
                  >
                    <List className="p-0">
                      {menu.accordion.map((accor, j) => (
                        <Link to={accor.link} key={accor.link + "1"}>
                          <ListItem
                            className="text-white text-sm group"
                            key={accor.link + 1}
                          >
                            <ListItemPrefix>
                              <ChevronRightIcon
                                strokeWidth={3}
                                className="h-3 w-5 group-hover:text-[#1b2c3e]"
                              />
                            </ListItemPrefix>
                            {accor.name}
                          </ListItem>
                        </Link>
                      ))}
                    </List>
                  </AccordionBody>
                </Accordion>
              )}
            </>
          ))}
        </List>
      </Card>
    </div>
  );
};

export default MaterialSideBar;
